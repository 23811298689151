.inputCol {
  border-bottom: 1px solid black;
  padding-top: 3px;
}

.inputCol > input {
  outline: none;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1rem;
}

.verifiedCol {
  border-right: 1px solid lightgray;
}

.verifiedIcon {
  width: 14px;
  padding: 2px 1.5px 0 1px;
}

.hidden {
  opacity: 0;
}

.bookCol, .authorCol {
  padding-left: 6px;
  padding-top: 2.5px;
}

.langCol {
  padding: 0 6px;
  border-right: 1px solid lightgray;
}

.authorCol {
  min-width: 200px;
  max-width: 200px;
}

.bookCol {
  width: 100%;
}

table {
  border-spacing: 0;
  user-select: none;
}

th:first-child {
  padding-left: 2px;
}

th {
  text-align: left;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

th:last-child {
  border-right: 1px solid black;
}

th:first-child {
  border-left: 1px solid black;
}

thead > tr:hover {
  background: initial !important;
  cursor: initial;
}

tr {
  border-bottom: 1px solid #dddddd;
}

tr:hover {
  background: #e1e1e1 !important;
  cursor: pointer;
}

tr:active {
  background: #d8d8d8 !important;
}

tr:nth-of-type(even) {
  background-color: #e6e6e6;
}

tr:last-child > td {
  border-bottom: 1px solid black;
}

td:last-child {
  border-right: 1px solid black;
}

td:first-child {
  border-left: 1px solid black;
}

td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 2px;
}

.progressCol {
  padding-right: 8px;
  padding-left: 8px;
  border-left: 1px solid lightgray;
}