.flashcards {
  height: 100%;
}

.flashcardColumn {
  width: 50%;
  height: calc(100% - 45px);
  position: absolute;
}

.flashcardColumn .left {
  border-right: 1px solid black;
  left: 0;
}

.flashcardColumn .right {
  right: 0;
}

.blur.unHoverBlur:hover {
  filter: none !important;
}

.flashcard {
  margin: 20px;
  padding: 20px;
  border: 1px solid black;
}