@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.googleButton {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border: thin solid #888;
  white-space: nowrap;
}

.googleButton:hover {
  cursor: pointer;
}

.googleButtonIcon {
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}

.googleButtonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 4px;
  padding-right: 42px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}